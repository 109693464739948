<template>
  <div :style="{display: 'flex', justifyContent: 'center', width: '100%'}">
    <div :style="{ padding: '30px 0', width:'50vw'}">
      <h1 >로그인</h1>
      <!-- <p>Enter your email address and password to access admin panel.</p> -->
      <sign-in-form formType="jwt" :email="email" :password="password"></sign-in-form>
    </div>
  </div>
  </template>

<script>
import SignInForm from './Forms/SignInForm.vue'
import { core } from '../../config/pluginInit'
export default {
  name: 'SignIn',
  components: { SignInForm },
  data () {
    return {
      email: '',
      password: ''
    }
  },
  mounted () {
    core.index()
    const loggedIn = localStorage.getItem('hypercert_access_token')
    if (loggedIn !== undefined && loggedIn !== null) {
      this.$router.push({ name: 'home.main' })
    }
  }
  // methods: {
  //   loginOAuth0: function () {
  //     new auth0.WebAuth(constant.auth0Config).authorize()
  //   }
  // }
}
</script>
