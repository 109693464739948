<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="mt-4" novalidate @submit.prevent="handleSubmit(onSubmit)">
      <ValidationProvider vid="email" name="Email" rules="required|email" v-slot="{ errors }">
        <div class="form-group">
          <label for="emailInput">Email</label>
          <input type="email" :class="'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : '')"
                 id="emailInput" aria-describedby="emailHelp"
                 v-model="user.account" placeholder="Enter email" required>
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <ValidationProvider vid="password" name="Password" rules="required" v-slot="{ errors }">
        <div class="form-group">
          <label for="passwordInput">비밀번호</label>
          <!-- <router-link to="/auth/password-reset1" class="float-right">
            잊어버리셨나요?
          </router-link> -->
          <input type="password"  :class="'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : '')"
                 id="passwordInput"
                 v-model="user.pw" placeholder="Password" required>
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <div class="d-inline-block w-100" :style="{paddingTop: '10px'}">
        <div class="custom-control custom-checkbox d-inline-block mt-2 pt-1">
          <input type="checkbox" class="custom-control-input" :id="formType">
          <!-- <label class="custom-control-label" :for="formType">자동 로그인</label> -->
        </div>
        <button type="submit" class="btn btn-primary float-right">로그인</button>
      </div>
      <div class="sign-info">
          <span class="dark-color d-inline-block line-height-2">
            <!-- <router-link :to="{name:'/auth/sign-up'}" class="iq-waves-effect pr-4"> -->
            <router-link to='/auth/sign-up' class="iq-waves-effect pr-4">
            계정이 없으신가요?
            </router-link>
          </span>
          <span class="dark-color d-inline-block line-height-2">
            <router-link to='/auth/password-reset' class="iq-waves-effect pr-4">
            비밀번호를 잃어버리셨나요?
            </router-link>
          </span>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
// import SocialLoginForm from './SocialLoginForm.vue'
import auth from '../../../services/auth'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SignInForm',
  props: ['formType', 'email', 'password'],
  data: () => ({
    user: {
      account: '',
      pw: ''
    }
  }),
  mounted () {
    this.user.account = this.$props.email
    this.user.pw = this.$props.password
  },
  computed: {
    ...mapGetters({
      stateUsers: 'Setting/usersState'
    })
  },
  methods: {
    ...mapActions({
      updateUserInfo: 'Auth/updateUserInfo'
    }),
    onSubmit () {
      this.jwtLogin()
    },
    jwtLogin () {
      auth.login(this.user).then(res => {
        if (res.data.resultCode === 'false') {
          alert('옳바른 계정이 아닙니다.')
        } else {
          localStorage.setItem('hypercert_access_token', res.data.jwt)
          auth.myInfo().then(res => {
            const _user = res.data.user
            _user.referral = res.data.referral

            this.updateUser(_user)
          })
          this.$router.go(0)
          // this.$router.go(-1)
        }
      })
    }
  }

}
</script>
